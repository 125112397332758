@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package eu.codlab.blipya.res

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val cancel: StringResource by 
      lazy { init_cancel() }

  public val confirm: StringResource by 
      lazy { init_confirm() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("cancel", CommonMainString0.cancel)
  map.put("confirm", CommonMainString0.confirm)
}

public val Res.string.cancel: StringResource
  get() = CommonMainString0.cancel

private fun init_cancel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cancel", "cancel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/eu.codlab.blipya.res/values-fr/locales.commonMain.cvr", 10, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/eu.codlab.blipya.res/values/locales.commonMain.cvr", 10, 22),
    )
)

public val Res.string.confirm: StringResource
  get() = CommonMainString0.confirm

private fun init_confirm(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:confirm", "confirm",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/eu.codlab.blipya.res/values-fr/locales.commonMain.cvr", 37, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/eu.codlab.blipya.res/values/locales.commonMain.cvr", 33, 27),
    )
)
