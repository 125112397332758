package eu.codlab.viewmodel.effects

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.platform.LocalLifecycleOwner
import androidx.lifecycle.Lifecycle
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch

@Composable
fun LifecycleEffect(
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
    handler: suspend (Lifecycle.State) -> Unit
) {
    val lifecycleOwner = LocalLifecycleOwner.current
    DisposableEffect(Unit) {
        val job = coroutineScope.launch {
            lifecycleOwner.lifecycle.currentStateFlow.collect {
                handler(it)
            }
        }
        onDispose { job.cancel() }
    }
}
