package eu.codlab.viewmodel

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.receiveAsFlow
import kotlinx.coroutines.launch

private class StateHandlerImpl<UiState>(defaultState: UiState) : StateHandler<UiState> {
    private val _state = MutableStateFlow(defaultState)
    override val states: StateFlow<UiState> = _state.asStateFlow()

    override fun setState(state: UiState) {
        _state.value = state
    }

    override fun updateState(block: UiState.() -> UiState) {
        _state.value = block(_state.value)
    }
}

private class ActionsHandlerImpl<UiAction> : ActionsHandler<UiAction> {
    private val _actions = Channel<UiAction>(capacity = Channel.BUFFERED)
    override val actions: Flow<UiAction> = _actions.receiveAsFlow()

    override suspend fun emitAction(action: UiAction) {
        _actions.send(action)
    }
}

@Suppress("UnnecessaryAbstractClass")
abstract class StateViewModel<UiState>(defaultState: UiState) :
    ViewModel(),
    StateHandler<UiState> by StateHandlerImpl(defaultState)

@Suppress("UnnecessaryAbstractClass")
abstract class ActionsViewModel<UiAction> :
    ViewModel(),
    ActionsHandler<UiAction> by ActionsHandlerImpl()

@Suppress("UnnecessaryAbstractClass")
abstract class StateActionsViewModel<UiState, UiAction>(defaultState: UiState) :
    ActionsViewModel<UiAction>(),
    StateHandler<UiState> by StateHandlerImpl(defaultState)

fun <UiAction> ActionsViewModel<UiAction>.action(action: UiAction) = viewModelScope.launch {
    emitAction(action)
}

/**
 * Launch a block in the current view model's scope
 */
@Suppress("TooGenericExceptionCaught")
fun ViewModel.launch(
    onError: (Throwable) -> Unit = {
        throw IllegalStateException(
            "Error block not implemented",
            it
        )
    },
    run: suspend CoroutineScope.() -> Unit
) = viewModelScope.launch {
    try {
        run(this)
    } catch (err: Throwable) {
        onError.invoke(err)
    }
}

/**
 * Launch a block in the current view model's scope while catching any throwable silently
 */
fun ViewModel.launchSilentFail(
    onError: (Throwable) -> Unit = { /* nothing */ },
    run: suspend CoroutineScope.() -> Unit
) = launch(onError, run)