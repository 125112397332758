package eu.codlab.viewmodel

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.remember
import dev.icerock.moko.mvvm.viewmodel.ViewModel

/**
 * Remember a specific ViewModel given an optional key
 *
 * @sample eu.codlab.viewmodel.samples.RememberViewModelSample
 */
@Composable
fun <T : ViewModel> rememberViewModel(
    key: String? = null,
    viewModelBlock: () -> T
): T {
    val viewModel: T = if (null != key) {
        remember(key, viewModelBlock)
    } else {
        remember(viewModelBlock)
    }

    DisposableEffect(viewModel) {
        onDispose { viewModel.onCleared() }
    }

    return viewModel
}
